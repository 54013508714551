<template>
    <LoadingWrapper
        :isLoading="!isOpen || isFetching"
        class="px-4 py-4"
    >
        <Panel
            id="select-profile-wrapper"
            class="mb-5"
        >
            <template #header>
                <div class="flex flex-row justify-between">
                    <h3
                        class="inline-flex flex-grow align-middle items-center text-xl text-primary-700 col-span-3"
                    >
                        View Dataset Fields
                    </h3>
                    <div
                        class="inline-flex align-middle text-right text-xl text-gray-700"
                    >
                        <SearchIcon class="h-5 w-5 mt-1 mr-2"></SearchIcon>
                        <div>Viewing</div>
                    </div>
                </div>
            </template>
            <template #default>
                <!-- MAPPING PROFILE > PREVIEW -->
                <FormSection
                    class="pt-4"
                    id="profile-preview"
                    title="Preview"
                    description="The following are sample values for your specified PEM file."
                    :grid="['grid', 'grid-cols-1', 'pt-4']"
                >
                    <div
                        class="grid grid-cols-1 mb-2 sm:grid-cols-2 sm:gap-x-2 text-sm text-gray-600"
                    >
                        <div>File: {{ targetFilename }}</div>
                        <div>Location: {{ targetLocationDisplayName }}</div>
                    </div>
                    <LoadingWrapper :isLoading="false">
                        <AgGridVue
                            class="ag-theme-alpine w-full block"
                            style="min-height: 250px"
                            :domLayout="grid.state.domLayout.value"
                            :defaultColDef="grid.state.defaultColDef.value"
                            :columnDefs="grid.properties.columnDefs.value"
                            :rowSelection="grid.state.rowSelection.value"
                            :rowHeight="grid.state.rowHeight.value"
                            :rowData="grid.properties.clampedRowData.value"
                            @grid-ready="grid.actions.onGridReady"
                            @column-resized="grid.actions.onColumnResized"
                            @selection-changed="grid.actions.onSelectionChanged"
                        />
                    </LoadingWrapper>
                </FormSection>
            </template>
            <template #footer>
                <div class="flex flex-row-reverse justify-between">
                    <div>
                        <div class="flex justify-end space-x-4">
                            <transition-group
                                enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95 translate-x-6"
                                enter-to-class="transform opacity-100 scale-100 translate-x-0"
                                leave-active-class="absolute transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100 translate-x-0"
                                leave-to-class="transform opacity-0 scale-95 -translate-x-6"
                            >
                                <ModalButton
                                    id="close-button"
                                    key="close-button"
                                    theme="white"
                                    :label="'Close'"
                                    @click.prevent="closeModal"
                                />
                            </transition-group>
                        </div>
                    </div>
                </div>
            </template>
        </Panel>
        <DebugFrame
            v-if="selector.state.open.value"
            id="generic"
            :startHidden="frame.startHidden"
            :debug="frame.isEnabled"
            :data="frame.data"
        />
    </LoadingWrapper>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, toRefs, ref } from 'vue';
    import { useStore } from 'vuex';
    import { UploadRecord } from '@/store/types/uploader/state/UploadRecord';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import { AgGridVue } from 'ag-grid-vue3';
    import { SearchIcon } from '@heroicons/vue/outline';

    import DebugFrame from '@/components/debug/DebugFrame.vue';

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import { useSelectProfileForm } from '~CSVUploader/hooks/form/useSelectProfileForm';
    import { useCSVPreviewGrid } from '~CSVUploader/hooks/grid/useCSVPreviewGrid';

    // <!-- TYPES -->

    /** @typedef {import('@/models/mappings/MappingProfile').MappingProfileResource} MappingProfileResource */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectProfileModal',
        components: {
            Panel,
            LoadingWrapper,
            FormSection,
            ModalButton,
            AgGridVue,
            SearchIcon,
            DebugFrame,
        },
        props: {
            selector: {
                /** @type {V.PropType<ReturnType<useSelectProfileForm>>} */
                type: Object,
                required: true,
            },
        },
        setup(props, context) {
            // ==== PROPS ====
            const store = useStore();
            const { selector } = toRefs(props);

            // ==== COMPOSABLES ====
            const {
                isOpen,
                isFetching,
                isFetchingIndex,
                isFetchingPreview,
                isFetchingProfile,
            } = selector.value.status;

            const {
                targetRecord,
                targetFilename,
                targetLocationPath,
                targetLocationDisplayName,
                targetContents,
                presetIndexOptions,
            } = selector.value.properties;

            const { closeModal, getFieldIndexOptions, getFieldFormatOptions } =
                selector.value.methods;

            const enabled = ref(true);

            // ==== COMPUTED PROPERTIES ====
            /** @type {V.ComputedRef<Map<String, UploadRecord>>} */
            const records = computed(() => {
                return store.state.uploader.data.records;
            });

            // Get the file grid module.
            const grid = useCSVPreviewGrid(context, {
                record: targetRecord,
            });

            /** Configure the grid module. */
            grid.useDefaultColDef({
                //flex: 1,
                minWidth: 50,
                //width: 100,
                resizable: true,
                sortable: true,
                filter: false,
                suppressSizeToFit: true,
            });
            grid.useContents(targetContents.value, false);
            // grid.useSchema();

            // DEBUG
            /**
             * Computed debug frame.
             */
            const frame = computed(
                () => {
                    /** @type {Map<String, UploadRecord>} */
                    const records = store.state.uploader.data.records;
                    const target = selector.value.state.target.value;
                    const record = target != '' ? records.get(target) : null;

                    // Prepare data.
                    const data = [
                        DebugObject.create(
                            `Show Profile Modal`,
                            selector.value.state.open.value
                        ),
                        DebugObject.create(`Record`, record),
                    ];

                    // Return new frame instance.
                    return useDebugFrame({
                        startHidden: true,
                        data,
                    });
                },
                {
                    // onTrack(e) {
                    //     debugger;
                    // },
                    // onTrigger(e) {
                    //     debugger;
                    // },
                }
            );

            // EXPOSE
            return {
                // REACTIVITY
                enabled,

                // PROPS
                targetRecord,
                targetFilename,
                targetLocationPath,
                targetLocationDisplayName,
                presetIndexOptions,
                grid,

                // METHODS
                closeModal,
                getFieldIndexOptions,
                getFieldFormatOptions,

                // DEBUG
                frame,

                // CONDITIONALS
                isOpen,
                isFetching,
                isFetchingIndex,
                isFetchingPreview,
                isFetchingProfile,
            };
        },
    });
</script>
